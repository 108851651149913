import React, { useEffect, useState } from 'react';
import { useSocket } from '../socket.context';
import { useUser } from '../helpers/UserContext';
import BaseLayout from '../layouts/BaseLayout'; // Import the BaseLayout
import GetCallsForOrganisation from '../components/GetCallsForOrganisation';

const Dashboard = () => {
  const [activity, setActivity] = useState([]);
  const [user, setUser] = useState(null)
  const { socket } = useSocket();
  const currentUser = useUser()

  useEffect(() => {
    setUser(currentUser)
  }, [])

  useEffect(() => {
    
    if (socket) {
      // Listen for 'roomActivity' event to update activity feed for incoming calls
      socket.on('roomActivity', (data) => {
        setActivity((prevActivity) => [
          ...prevActivity,
          { roomName: data.roomName, action: data.action },
        ]);
      });

      // Listen for 'callCanceled' event to update the activity feed with canceled calls
      socket.on('callCanceled', (data) => {
        // The data will include message, roomName, and canceledBy
        setActivity((prevActivity) => [
          ...prevActivity,
          { 
            kioskName: data.kioskName, 
            action: `Call canceled by guest (${data.canceledBy})` 
          }
        ]);
      });
    }

    return () => {
      if (socket) {
        socket.off('roomActivity');
        socket.off('callCanceled'); // Unsubscribe from the event when the component unmounts
      }
    };
  }, [socket]);

  return (
    <BaseLayout>
      {/* The content for this page will be passed into the BaseLayout */}
      { user && 
        <GetCallsForOrganisation organisationId={user.organisationId} />
      }
      <ul>
        {activity && activity.map((event, index) => (
          <li key={index}>
            {event.kioskName} - {event.action}
          </li>
        ))}
      </ul>
    </BaseLayout>
  );
};

export default Dashboard;