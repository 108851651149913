import { useState, useEffect } from 'react';
import { useSocket } from '../socket.context';

const useRoomActivity = () => {
  const [activity, setActivity] = useState([]);
  const { socket } = useSocket();

  useEffect(() => {
    if (socket) {
      socket.on('roomActivity', (data) => {
        setActivity((prevActivity) => [
          ...prevActivity,
          { roomName: data.roomName, action: data.action },
        ]);
      });
    }

    return () => {
      if (socket) {
        socket.off('roomActivity');  // Cleanup socket event listener
      }
    };
  }, [socket]);

  return activity;
};

export default useRoomActivity;
