// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Style for local video feed */
.local-video {
  position: absolute;
  top: 10px;
  right: 10px;
  border: 2px solid #fff;
  border-radius: 5px;
  width: 150px;
  height: 200px;
  z-index: 1;
}

/* Style for remote video feed */
.remote-video {
  margin: 10px;
  border-radius: 5px;
  width: 400px;
  height: 300px;
  background-color: black;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA,+BAA+B;AAC/B;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,UAAU;AACZ;;AAEA,gCAAgC;AAChC;EACE,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,uBAAuB;AACzB","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n/* Style for local video feed */\n.local-video {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  border: 2px solid #fff;\n  border-radius: 5px;\n  width: 150px;\n  height: 200px;\n  z-index: 1;\n}\n\n/* Style for remote video feed */\n.remote-video {\n  margin: 10px;\n  border-radius: 5px;\n  width: 400px;\n  height: 300px;\n  background-color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
