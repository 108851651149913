// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video--wrapper {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
}

.video--wrapper #local-media {
    width: 50%;
    height: 100%;
    background-color: #e6f2f6;
    position: relative;
    overflow: hidden;
}

.video--wrapper #local-media div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the video covers the entire div */
}

.video--wrapper #local-media video{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video--wrapper #remote-media {
    width: 50%;
    height: 100%;
    background-color: #024e64;
    position: relative;
    overflow: hidden;
}

.video--wrapper #remote-media div {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the video covers the entire div */
}

.video--wrapper #remote-media div video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/components/video/RoomComponent.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,SAAS;IACT,UAAU;IACV,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,WAAW;IACX,YAAY;IACZ,iBAAiB,EAAE,2CAA2C;AAClE;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,iBAAiB,EAAE,2CAA2C;AAClE;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".video--wrapper {\n    width: 100%;\n    height: 100%;\n    margin: 0;\n    padding: 0;\n    display: flex;\n}\n\n.video--wrapper #local-media {\n    width: 50%;\n    height: 100%;\n    background-color: #e6f2f6;\n    position: relative;\n    overflow: hidden;\n}\n\n.video--wrapper #local-media div {\n    position: absolute;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    object-fit: cover; /* Ensure the video covers the entire div */\n}\n\n.video--wrapper #local-media video{\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}\n\n.video--wrapper #remote-media {\n    width: 50%;\n    height: 100%;\n    background-color: #024e64;\n    position: relative;\n    overflow: hidden;\n}\n\n.video--wrapper #remote-media div {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    object-fit: cover; /* Ensure the video covers the entire div */\n}\n\n.video--wrapper #remote-media div video{\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
