import { useNavigate } from 'react-router-dom';
import { useSocket } from '../socket.context';
import { useActiveStatus } from '../helpers/ActiveStatusContext';

const useLogout = () => {
  const { socket } = useSocket();
  const navigate = useNavigate();
  const { logout } = useActiveStatus();

  const handleLogout = () => {
    if (socket) {
      socket.emit('logout');
    }
    sessionStorage.removeItem('jwt_token');
    sessionStorage.removeItem('socketId');
    logout();  // Update the active status context
    navigate('/login');  // Redirect to login after logout
  };

  return handleLogout;
};

export default useLogout;
