import React from 'react';
import { useUser } from '../helpers/UserContext';
import OrganisationsList from '../components/OrganisationsList';
import KiosksList from '../components/KiosksList';
import BaseLayout from '../layouts/BaseLayout';

const SuperAdmin = () => {
  const user = useUser();
  
  return (
    <BaseLayout>
      <div className='superadmin--flex'>
        <OrganisationsList />
        <KiosksList />
        {/* Activity Feed (optional, can display the room activities) */}
      </div>
    </BaseLayout>
  );
};

export default SuperAdmin;