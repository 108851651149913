import React, { createContext, useContext, useState, useEffect } from 'react';

// Create a context for managing the active status state
const ActiveStatusContext = createContext();

// Provider component to wrap the app and provide the context values
export const ActiveStatusProvider = ({ children }) => {
  const [isActive, setIsActive] = useState(false);

  // Retrieve and set the state from sessionStorage when the component mounts
  useEffect(() => {
    const savedStatus = sessionStorage.getItem('isActive');
    if (savedStatus !== null) {
      setIsActive(JSON.parse(savedStatus)); // Parse it as boolean
    }
  }, []);

  // Toggle the isActive state and persist it in sessionStorage
  const toggleActiveStatus = () => {
    setIsActive((prevState) => {
      const newState = !prevState;
      sessionStorage.setItem('isActive', JSON.stringify(newState)); // Save status to sessionStorage
      return newState;
    });
  };

  // Handle logout by resetting active status to false and clearing sessionStorage
  const logout = () => {
    setIsActive(false);
    sessionStorage.setItem('isActive', JSON.stringify(false)); // Persist the 'false' status
  };

  return (
    <ActiveStatusContext.Provider value={{ isActive, toggleActiveStatus, logout }}>
      {children}
    </ActiveStatusContext.Provider>
  );
};

// Custom hook to use the active status context
export const useActiveStatus = () => {
  return useContext(ActiveStatusContext);
};
