import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';  // useNavigate is the correct way to navigate in React Router v6
import { UserContext } from '../helpers/UserContext'; // Assuming this is where the context is located
import { jwtDecode  } from 'jwt-decode';  // Corrected the import for jwt-decode
import Unauthorized from './Unauthorized';

const ProtectedRoute = ({ children, accessRole }) => {
  const navigate = useNavigate();  // Correct hook to navigate in React Router v6
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const token = sessionStorage.getItem('jwt_token');  // Get JWT from sessionStorage

    if (!token) {
      // If no token, redirect to login page
      navigate('/login');
    } else {
      try {
        const decodedToken = jwtDecode(token);  // Decode the token
        const expirationDate = decodedToken.exp * 1000; // Convert exp to ms

        if (expirationDate < Date.now()) {
          // Token expired
          sessionStorage.removeItem('jwt_token');  // Clear token from sessionStorage
          navigate('/login');
        } else {
          // Set user data if token is valid
          setUserData({
            email: decodedToken.email,
            role: decodedToken.role,
            organisationId: decodedToken.organisationId,
            organisationName: decodedToken.organisationName
          });
          setIsAuthenticated(true);  // Token is valid, allow access

        }
      } catch (error) {
        console.error('Error decoding token:', error);
        sessionStorage.removeItem('jwt_token');  // Invalidate token if decoding fails
        navigate('/login');
      }
    }
  }, [navigate]);

  // Render children if the user is authenticated, otherwise return null (loading spinner is an option)
  if (!isAuthenticated) {
    return null;  // You can add a loading spinner here if needed
  }

  return (
    <>
      { accessRole === undefined || accessRole === null || accessRole === userData.role ? 
        <UserContext.Provider value={userData}>
          {/* Render the component passed via 'children' prop */}
          { children }
        </UserContext.Provider>
        : <Unauthorized/>
      }
    </>
  );
};

export default ProtectedRoute;
