import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { CircularProgress, Box, Typography, Card, CardContent } from '@mui/material';

const OrganisationDetail = () => {
  const { organisationId } = useParams(); // Get the organisation ID from the URL
  const [organisation, setOrganisation] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrganisationDetails = async () => {
      try {
        const response = await axios.get(`https://memoreceptie-api-gmeef6gqceg7bugp.westeurope-01.azurewebsites.net/organisations/${organisationId}`);
        setOrganisation(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching organisation details:', error);
        setLoading(false);
      }
    };

    fetchOrganisationDetails();
  }, [organisationId]); // Re-run the effect when the ID changes

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh" width="30%">
        <CircularProgress />
      </Box>
    );
  }

  if (!organisation) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h6">Organisation not found</Typography>
      </Box>
    );
  }

  return (
    <>
      <Card sx={{ width: '25%', display: 'flex', alignItems:'flex-start', maxWidth: '800', marginTop: '1rem'}}>
        <CardContent sx={{ width: '100%' }}>
          <Typography variant="h4" component="div" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            {organisation.name}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            <strong>Created By:</strong> {organisation.createdBy} {/* You can populate with email if you have that */}
          </Typography>
          {/* Add more fields here if necessary */}
        </CardContent>
      </Card>
    </>
  );
};

export default OrganisationDetail;
