import { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';

const SocketContext = createContext();

export const SocketProvider = ({ children, userId }) => {
  const [socket, setSocket] = useState(null);
  const [connected, setConnected] = useState(false); // Track connection state

  useEffect(() => {

    // Check if there is a saved JWT token in localStorage
    const token = sessionStorage.getItem('jwt_token'); // Or sessionStorage or cookies
    const savedSocketId = sessionStorage.getItem('socketId');
    
    // Define the query parameters, including the token if available
    const query = {
      userId,
      socketId: savedSocketId,
      token: token || '', // If no token, send an empty string
    };

    const socketInstance = io('wss://memoreceptie-api-gmeef6gqceg7bugp.westeurope-01.azurewebsites.net', {
      transports: ['websocket'], // Prefer WebSocket transport for better performance
      query, // Send the query with the user data and token
    });

    socketInstance.on('connect', () => {
      setSocket(socketInstance);
      setConnected(true); // Set connected state to true when socket connects
      sessionStorage.setItem('socketId', socketInstance.id); // Store socket ID in sessionStorage

      // Listen for the 'loginSuccess' event
      socketInstance.on('loginSuccess', (data) => {
        if (data.socketId) {
          // Store the client ID in sessionStorage if login is successful
          sessionStorage.setItem('socketId', data.socketId);
        }
      });
    });

    socketInstance.on('disconnect', () => {
      setConnected(false); // Set connected state to false when socket disconnects
    });

    socketInstance.on('connect_error', (error) => {
      console.error('Socket connection error:', error);
      setConnected(false); // Update connected state on error
    });

    // Cleanup function: disconnect socket and remove listeners when component unmounts
    return () => {
      socketInstance.disconnect();
      setSocket(null); // Reset socket state
      setConnected(false); // Reset connected state
    };
  }, [userId]); // Use userId as a dependency to reconnect if it changes

  return (
    <SocketContext.Provider value={{ socket, connected }}>
      {children}
    </SocketContext.Provider>
  );
};

// Custom hook to access the socket context
export const useSocket = () => {
  return useContext(SocketContext);
};
