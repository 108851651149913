import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Typography, List, ListItem, ListItemText, Alert, TextField, Button, Modal, Fade, Backdrop, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

const UsersByOrganisation = () => {
  const { organisationId } = useParams();  // Extract organisationId from URL
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [openModal, setOpenModal] = useState(false);

  // Function to fetch users
  const fetchUsers = async () => {
    const token = sessionStorage.getItem('jwt_token');  // Retrieve the token from sessionStorage

    if (!token) {
      setError('You must be logged in to view users.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(
        `https://memoreceptie-api-gmeef6gqceg7bugp.westeurope-01.azurewebsites.net/users/organisation/${organisationId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUsers(response.data);
    } catch (err) {
      setError('Error fetching users');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers(); // Fetch users when the component mounts or organisationId changes
  }, [organisationId]);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleSubmit = async (values) => {
    const token = sessionStorage.getItem('jwt_token');

    if (!token) {
      setError('You must be logged in to create a user.');
      return;
    }

    try {
      await axios.post(
        `https://memoreceptie-api-gmeef6gqceg7bugp.westeurope-01.azurewebsites.net/auth/register`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setError('User created successfully!');
      setOpenModal(false);
      fetchUsers();  // Re-fetch users to show the new user
    } catch (err) {
      setError('Error creating user');
      console.error(err);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  // Filter users based on search term
  const filteredUsers = users.filter(user => 
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    
    <Box sx={{ padding: 0, marginTop: '1rem' }}>
      <Box sx={{
        backgroundColor: '#0288d1', 
        color: 'white', 
        padding: '10px 20px', 
        marginBottom: '20px', 
        borderRadius: '4px', 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center'
      }}>
        <Typography className='kiosk--box__header'>
          Total Users: {filteredUsers.length}
        </Typography>
      </Box>
      {/* Search and Add User Section */}
      <Box sx={{ display: 'flex', marginBottom: '20px' }}>
        <TextField
          label="Search by email"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ marginRight: '10px', flex: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ flex: 1 }}
          onClick={handleOpenModal}  // Open modal when clicked
        >
          Add New User
        </Button>
      </Box>

      {filteredUsers.length === 0 ? (
        <Typography variant="body1" color="textSecondary">
          No users found for this organisation.
        </Typography>
      ) : (
        <List>
          {filteredUsers.map((user) => (
            <ListItem key={user._id} style={{ backgroundColor: '#e6f2f6', marginBottom: '10px'}}>
              <ListItemText
                primary={user.role}
                secondary={`${user.email}`}
              />
            </ListItem>
          ))}
        </List>
      )}

      {/* Modal for Adding New User */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Create New User
            </Typography>

            <Formik
              initialValues={{
                email: '',
                password: '',
                role: '',
                organisation_id: organisationId, // Pre-fill organisation_id from URL
              }}
              validationSchema={Yup.object({
                email: Yup.string().email('Invalid email address').required('Email is required'),
                password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
                role: Yup.string().required('Role is required'),
                organisation_id: Yup.string().required('Organisation ID is required'),
              })}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange, handleSubmit, errors, touched }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    as={TextField}
                    value={values.email}
                    onChange={handleChange}
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    sx={{ marginBottom: 2 }}
                  />
                  <Field
                    name="password"
                    label="Password"
                    variant="outlined"
                    fullWidth
                    type="password"
                    as={TextField}
                    value={values.password}
                    onChange={handleChange}
                    error={touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                    sx={{ marginBottom: 2 }}
                  />
                  {/* Role select input */}
                  <FormControl fullWidth sx={{ marginBottom: 2 }}>
                    <InputLabel>Role</InputLabel>
                    <Select
                      label="Role"
                      name="role"
                      value={values.role}
                      onChange={handleChange}
                      error={touched.role && !!errors.role}
                    >
                      <MenuItem value="user">User</MenuItem>
                      <MenuItem value="admin">Admin</MenuItem>
                      <MenuItem value="superadmin">SuperAdmin</MenuItem>
                    </Select>
                    {touched.role && errors.role && <Typography color="error">{errors.role}</Typography>}
                  </FormControl>
                  <Field
                    name="organisation_id"
                    label="Organisation ID"
                    variant="outlined"
                    fullWidth
                    value={values.organisation_id}
                    disabled
                    as={TextField}
                    sx={{ marginBottom: 2 }}
                  />
                  <Box display="flex" justifyContent="flex-end">
                    <Button variant="contained" color="primary" type="submit">
                      Create User
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default UsersByOrganisation;
