import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate()

    const kioskId = localStorage.getItem('kioskId')
    const organisationId = localStorage.getItem('organisationId')
    const jwt_token = sessionStorage.getItem('jwt_token')

    return (
        <>
            <div style={{ padding: '50px', textAlign: 'center', color: '#024e64' }}>
            <h1 style={{ fontSize: '5rem', margin: '0' }}>404</h1>
            <h2>Oops! Pagina niet gevonden.</h2>
            <p>De pagina die je probeerde te bereiken is niet beschikbaar.</p>
            {
                (kioskId && organisationId) && (jwt_token === null || jwt_token === undefined) ?
                <Button variant="contained" color="primary" onClick={() => navigate(`/kiosk/${organisationId}/${kioskId}`)}>Ga terug</Button>
                :
                <Button variant="contained" color="primary" onClick={() => navigate('/')}>Ga terug</Button>
            }
            
            </div>
        </>
    );
};

export default NotFound;