import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Container, Paper, Box, CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios'; // Assuming you are using axios for HTTP requests
import BaseLayout from '../layouts/BaseLayout'
import { useUser } from '../helpers/UserContext';
import useLogout from '../hooks/useLogout';

const Profile = () => {

  // Simulate getting the user email (you could also use context or state management for this)
  const [userEmail, setUserEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false);
  const handleLogout = useLogout();

  const currentUser = useUser();

  useEffect(() => {
    // Assume you get the email from localStorage or context
    const email = currentUser.email
    setUserEmail(email);
  }, []);

  // Validation schema for password change form
  const validationSchema = Yup.object({
    newPassword: Yup.string()
      .required('New password is required')
      .min(8, 'New password must be at least 8 characters'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm your new password'),
  });

  // Formik setup
  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        // Call your API to change the password
        const response = await axios.patch(`https://memoreceptie-api-gmeef6gqceg7bugp.westeurope-01.azurewebsites.net/users/${userEmail}/change-password`, {
          newPassword: values.newPassword,
        });

        // If password change is successful
        if (response.status === 200) {
          setPasswordChangeSuccess(true);
          handleLogout()
        }
      } catch (error) {
        console.error('Error changing password', error);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <BaseLayout>
      <Container component="main" maxWidth="xs" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 8 }}>
        <Paper elevation={6} sx={{ padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          <Typography variant="h5" sx={{ marginBottom: 2 }}>
            Profiel
          </Typography>

          <Box sx={{ width: '100%', marginBottom: 5 }}>
            <Typography variant="body1">
              <strong>Email:</strong> {userEmail}
            </Typography>
          </Box>

          <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
            <TextField
              label="Nieuw wachtwoord"
              name="newPassword"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
              helperText={formik.touched.newPassword && formik.errors.newPassword}
            />
            <TextField
              label="Bevestig nieuw wachtwoord"
              name="confirmPassword"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ marginTop: 2 }}
              color="primary"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Change Password'}
            </Button>

            {passwordChangeSuccess && (
              <Typography color="success" variant="body2" sx={{ marginTop: 2 }}>
                Password changed successfully!
              </Typography>
            )}
          </form>
        </Paper>
      </Container>
    </BaseLayout>
  );
};

export default Profile;
