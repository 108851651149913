// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.guest--video {
    background-color: white;
    width: 100%;
    height: 100vh;
}

.guest--video #local-media {
    background-color: #024e64;
    height: 24rem;
    width: 13.5rem;
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 1rem;
    border-radius: 1rem;
    z-index: 99;
    overflow: hidden;
}

.guest--video #local-media video{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.guest--video #remote-media {
    background-color: #e6f2f6;
    height: 100vh;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1; 
}

.guest--video #remote-media {
    width: 100%;
    height: 100%;
    background-color: #024e64;
    overflow: hidden;
}

.guest--video #remote-media div {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%); /* Center the video inside the container */
}

.guest--video #remote-media div video{
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the video covers the entire div */
}`, "",{"version":3,"sources":["webpack://./src/pages/VideoRoom.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,cAAc;IACd,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,YAAY;IACZ,mBAAmB;IACnB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,WAAW;IACX,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,UAAU;AACd;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,WAAW;IACX,YAAY;IACZ,gCAAgC,EAAE,0CAA0C;AAChF;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB,EAAE,2CAA2C;AAClE","sourcesContent":[".guest--video {\n    background-color: white;\n    width: 100%;\n    height: 100vh;\n}\n\n.guest--video #local-media {\n    background-color: #024e64;\n    height: 24rem;\n    width: 13.5rem;\n    position: absolute;\n    right: 0;\n    bottom: 0;\n    margin: 1rem;\n    border-radius: 1rem;\n    z-index: 99;\n    overflow: hidden;\n}\n\n.guest--video #local-media video{\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}\n\n.guest--video #remote-media {\n    background-color: #e6f2f6;\n    height: 100vh;\n    width: 100%;\n    position: absolute;\n    left: 0;\n    top: 0;\n    z-index: 1; \n}\n\n.guest--video #remote-media {\n    width: 100%;\n    height: 100%;\n    background-color: #024e64;\n    overflow: hidden;\n}\n\n.guest--video #remote-media div {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    width: 100%;\n    height: 100%;\n    transform: translate(-50%, -50%); /* Center the video inside the container */\n}\n\n.guest--video #remote-media div video{\n    width: 100%;\n    height: 100%;\n    object-fit: cover; /* Ensure the video covers the entire div */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
