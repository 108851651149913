import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

const Unauthorized = () => {
    const navigate = useNavigate()
  return (
    <div style={{ padding: '20px', textAlign: 'center', fontSize: '18px', marginTop: '5rem' }}>
      <h2>Je hebt geen toegang tot deze pagina.</h2>
      <p style={{ marginBottom: '20rem'}}>Contacteer je administrator voor toegang.</p>
      <Button variant="contained" color="primary" onClick={() => navigate('/')}>Terug naar dashboard</Button>
    </div>
  );
};

export default Unauthorized;