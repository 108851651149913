import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSocket } from '../socket.context';
import { useUser } from '../helpers/UserContext';
import AudioRingTone from '../media/audio/ringtone.mp3';
import OrganisationDetail from '../components/OrganisationDetail';
import UsersByOrganisation from '../components/UsersByOrganisation';
import BaseLayout from '../layouts/BaseLayout';

const Organisation = () => {
  const [activity, setActivity] = useState([]);
  const { socket } = useSocket();
  const navigate = useNavigate();
  const user = useUser();  // Make sure this is returning a valid user object
  const [incomingCall, setIncomingCall] = useState(false);
  const [callingUserId, setCallingUserId] = useState(null)
  const [callingRoomname, setCallingRoomname] = useState(null)
  const [ringtone, setRingtone] = useState(null); // To store the ringtone audio object

  // Handle logout
  const handleLogout = () => {
    socket.emit('logout');
    sessionStorage.removeItem('jwt_token');
    sessionStorage.removeItem('socketId');
    navigate('/login');  // Redirect to login after logout
  };

  // Handle pick up action
  const handlePickUp = () => {
    if (ringtone) {
      ringtone.pause();  // Stop the ringtone
      ringtone.currentTime = 0;  // Reset the ringtone to the start
    }
    setIncomingCall(false);  // Hide the call controls after pick up
    if (socket) {
      socket.emit('acceptCall', { guestSocketId: callingUserId, roomName: callingRoomname });
    }
  };

  // Handle decline action
  const handleDecline = () => {
    if (ringtone) {
      ringtone.pause();  // Stop the ringtone
      ringtone.currentTime = 0;  // Reset the ringtone to the start
    }
    setIncomingCall(false);  // Hide the call controls after decline
    if (socket) {
      socket.emit('declineCall', { guestSocketId: callingUserId });
    }
  };

  // Get current time for the activity feed
  const getCurrentTime = () => {
    let d = new Date();
    return d.toString();
  };

  useEffect(() => {
    if (socket) {
      // Listen for 'incomingCall' event
      socket.on('incomingCall', (data) => {
        setCallingUserId(data.guestSocketId);
        setCallingRoomname(data.roomName);
        setIncomingCall(true);

        // Play ringtone or trigger other visual indicators here
        const newRingtone = new Audio(AudioRingTone);
        setRingtone(newRingtone); // Save the audio object in state
        newRingtone.play();
      });

      // Listen for 'roomActivity' event
      socket.on('roomActivity', (data) => {
        setActivity((prevActivity) => [
          ...prevActivity,
          { roomName: data.roomName, action: data.action },
        ]);
        
        // Handle incoming call when action is 'incomingCall' from roomActivity
        if (data.action === 'incomingCall') {
          setIncomingCall(true);

          // Play ringtone or trigger other visual indicators here
          const newRingtone = new Audio(AudioRingTone);
          setRingtone(newRingtone); // Save the audio object in state
          newRingtone.play();
        }
      });
    }

    return () => {
      if (socket) {
        // Cleanup socket event listeners
        socket.off('incomingCall');
        socket.off('roomActivity');
      }
    };
  }, [socket]);

  // Render safely, check for null user
  if (!user) {
    return <div>Loading...</div>; // Show loading state if user is not available yet
  }

  return (
    <>
      <BaseLayout>
        <div className="dashboard-container">
          {/* Main content area */}
          <div className="main-content">
            <OrganisationDetail />
            <UsersByOrganisation />
          </div>
        </div>
      </BaseLayout>
    </>
  );
};

export default Organisation;
