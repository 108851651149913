import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import VideoRoom from './pages/VideoRoom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Admin from './pages/Admin';
import { SocketProvider } from './socket.context'; // Import SocketProvider;
import ProtectedRoute from './components/ProtectedRoute';
import SuperAdmin from './pages/SuperAdmin';
import Organisation from './pages/Organisation';
import { ActiveStatusProvider } from './helpers/ActiveStatusContext';
import Profile from './pages/Profile';
import NotFound from './components/NotFound';

const App = () => {

  return (
    <SocketProvider>  {/* Wrap the app with SocketProvider */}
      <ActiveStatusProvider>
        <Router>
          <Routes>
            {/* Define routes for different paths */}
            <Route path="/login" element={<Login />} />
            <Route path="/video-room/:identity/:roomname" element={<VideoRoom />} />
            <Route path="/kiosk/:identity/:roomname" element={<Home />} /> {/* Home route */}
            <Route path="/" element={
              <ProtectedRoute>
                <Dashboard/> 
              </ProtectedRoute>} 
            />
            <Route path="/superadmin" element={
              <ProtectedRoute accessRole="superadmin">
                <SuperAdmin/> 
              </ProtectedRoute>} 
            />
            <Route path="/superadmin/org/:organisationId" element={
              <ProtectedRoute accessRole="superadmin">
                <Organisation /> 
              </ProtectedRoute>} 
            />
            <Route path="/admin" element={
              <ProtectedRoute accessRole="admin">
                <Admin/>
              </ProtectedRoute>} 
            />
            <Route path="/profile" element={
              <ProtectedRoute>
                <Profile/>
              </ProtectedRoute>} 
            />
            <Route path="*" element={<NotFound />} />
            {/* Add other routes as needed */}
          </Routes>
        </Router>
        </ActiveStatusProvider>
      </SocketProvider>
  );
};

export default App;
