import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import RoomComponent from '../components/video/RoomComponent';
import './VideoRoom.css'

const VideoRoom = () => {
  const { identity, roomname } = useParams(); // Get the identity and roomname from the URL
  const [error, setError] = useState('');
  const [token, setToken] = useState(null);
  const navigate = useNavigate(); // Hook to programmatically navigate to the VideoRoom

  useEffect(() => {
    const token = localStorage.getItem('twilioToken');
    setToken(token);

    // Check if we have a token and identity
    if (!token) {
      setError('Missing identity or token');
      return;
    }
  }, []);

  return (
    <div className="guest--video">
      {/* Render the RoomComponent */}
      <RoomComponent identity={roomname} roomname={roomname} />
    </div>
  );
};

export default VideoRoom;
