import React, { useEffect, useState } from 'react';
import { useSocket } from '../socket.context';  // Use socket context to send updates
import { useUser } from '../helpers/UserContext';
import { useActiveStatus } from '../helpers/ActiveStatusContext';
import { ImPhoneHangUp } from "react-icons/im";
import { AiFillPhone } from "react-icons/ai";
import { Button, Switch  } from '@mui/material';  // Modal components
import RoomComponent from './video/RoomComponent';

const Header = ({ incomingCall, onPickUp, onDecline, callingRoomName, callingKiosk }) => {

  const { isActive, toggleActiveStatus } = useActiveStatus();  // Access context state and toggle function
  const [user, setUser] = useState('')
  const { socket } = useSocket();
  const currentUser = useUser();
  const [timer, setTimer] = useState(0); // Timer state to track duration in seconds
  const [isTimerRunning, setIsTimerRunning] = useState(false); // Flag to control when the timer is running

  useEffect(() => {
    setUser(currentUser)
  }, [])

  // // State to manage modal open/close
  const [isModalOpen, setIsModalOpen] = useState(false);

  // When the socket connection is available, emit the status update
  useEffect(() => {
    if (socket && socket.id) {

      // Emit 'setActiveStatus' only when there is a change in status
      if (isActive !== user.isActive) {  // Ensure status change
        try {
          socket.emit('setActiveStatus', isActive);
        } catch (err) {
          console.error("Error in socket.emit:", err);
        }
      }
    }
  }, [isActive, socket, user.isActive]); // Only emit when `isActive` changes

  const handleAcceptCall = () => {
    // First, execute the original onPickUp logic (if needed)
    if (onPickUp) {
      onPickUp();  // Make sure this function is passed correctly
    }
    // Then, open the modal
    setIsModalOpen(true);
    setIsTimerRunning(true);
  };

  const closeModal = () => {
    socket.emit('leaveRoom');
    setIsModalOpen(false);
    setIsTimerRunning(false);
    setTimer(0);
  }

  return (
    <header className='header'>
      {/* Active status switch */}
      <div className='active--switch'>
        <Switch
          checked={isActive}
          onChange={toggleActiveStatus}  // Use the toggle function from the context
          color="primary"
          name="isActive"
        />
        <span>{isActive ? 'Actief' : 'Inactief'}</span>
      </div>

      {/* Incoming call notification */}
      {incomingCall && (
        <div className='call--container'>
          <div className='call--info'><p>Inkomende oproep van <strong>{callingKiosk}</strong></p></div>
          <Button onClick={handleAcceptCall} variant="contained" color="primary" className='pickup--button'>
            <AiFillPhone /> Accepteer
          </Button>
          <Button onClick={onDecline} variant="contained" color="error" className='decline--button'>
            <ImPhoneHangUp/> Weiger
          </Button>
        </div>
      )}
      {user.email && isTimerRunning ? (
        <RoomComponent identity={user.email} organisation={user.organisationId} roomname={callingKiosk} isModalOpen={isModalOpen} closeModal={closeModal } timerCheck={isTimerRunning} />
      ) : (
        null  // Optionally show a loading message if user.email is not yet available
      )}
    </header>
  );
};

export default Header;