import { useEffect, useState, useRef } from "react";
import axios from 'axios';
import './RoomComponent.css';
import { useSocket } from '../../socket.context';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button } from "@mui/material";
import { ImPhoneHangUp } from "react-icons/im";
import { FaVideo } from "react-icons/fa";
import { CgMediaLive } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import * as Video from 'twilio-video'

const RoomComponent = ({ identity, roomname, isModalOpen, closeModal, timerCheck, organisation }) => {
    const [token, setToken] = useState('');
    const [room, setRoom] = useState(null);
    const [timer, setTimer] = useState(0);
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const [started, setStarted] = useState(false)
    const {socket} = useSocket()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showThanksMessage, setShowThanksMessage] = useState(false); // State for showing thanks message
    const [opacity, setOpacity] = useState(1); // State for controlling fade effect
    const [localTracks, setLocalTracks] = useState(null); // State to hold the local tracks
    const [error, setError] = useState(null); // State to track any errors
    const roomRef = useRef(null);

    const navigate = useNavigate();

    const oid = localStorage.getItem('organisationId');
    const kid = localStorage.getItem('kioskId');

    // Use useEffect to trigger the modal opening when 'isOpen' changes
    useEffect(() => {
      if (isModalOpen) {
        setIsModalVisible(true);
        } else {
        setIsModalVisible(false)
        }  // Open the modal when `isOpen` is true
    }, [isModalOpen]); // Re-run the effect when the `isOpen` prop changes

    // Fetch the token from localStorage or your server
    useEffect(() => {

        const fetchToken = async () => {
            try {
                const tokenResponse = await axios.get(`https://memoreceptie-api-gmeef6gqceg7bugp.westeurope-01.azurewebsites.net/twilio/video-token/${identity}/${roomname}`);
                if (tokenResponse.data) {
                    setToken(tokenResponse.data);
                } else {
                }
            } catch (err) {
                console.error('Error fetching token:', err);
            }
        };

        fetchToken();
    }, [identity, roomname]);

    // Set up the room connection when the token changes
    useEffect(() => {
        if (token) {
           Video.connect(token, roomname)
           .then(room => {
            roomRef.current = room; // Store the room in the ref
            room.participants.forEach(participantConnected);
            room.on('participantConnected', participantConnected);

            // Function to handle creating and attaching the local tracks
            // Helper function to attach the tracks to the container
            const attachTracks = (tracks, container) => {
            tracks.forEach(track => {
                container.appendChild(track.attach());
            });
            };

            // Function to handle creating and attaching the local tracks
            const createAndAttachTracks = async () => {
            try {
                // Create the local tracks
                const tracks = await Video.createLocalTracks();

                // Set the tracks in state
                setLocalTracks(tracks);

                // Attach the tracks to the preview container
                const previewContainer = document.getElementById('local-media');
                if (previewContainer && !previewContainer.querySelector('video')) {
                attachTracks(tracks, previewContainer);
                }

                // Add the identity name to the container
                const identityContainer = document.createElement('div');
                identityContainer.classList.add('identity-container');
                previewContainer.appendChild(identityContainer);

            } catch (error) {
                console.error('Unable to access local media', error);
                setError('Unable to access Camera and Microphone');
            }
            };

            // Call the function when the component mounts
            createAndAttachTracks();
              
            room.on('participantDisconnected', participantDisconnected);
            room.once('disconnected', error => room.participants.forEach(participantDisconnected));
           })
           function participantConnected(participant) {
          
            const div = document.createElement('div');
            div.id = participant.sid;
          
            participant.on('trackSubscribed', track => trackSubscribed(div, track));
            participant.on('trackUnsubscribed', trackUnsubscribed);
          
            participant.tracks.forEach(publication => {
              if (publication.isSubscribed) {
                trackSubscribed(div, publication.track);
              }
            });
          
            document.getElementById('remote-media').appendChild(div);
          }
          
          function participantDisconnected(participant) {
            
            // If localTracks is set, clean up local media
            if (localTracks && localTracks.length > 0) {
                localTracks.forEach(track => {
                    track.stop(); // Stop local track
                    track.detach().forEach(element => element.remove()); // Detach from DOM
                });
                setLocalTracks(null); // Reset localTracks after cleanup
            }
        
            // Clean up remote tracks (for the disconnected participant)
            participant.tracks.forEach(publication => {
                if (publication.track) {
                    trackUnsubscribed(publication.track); // Handle track cleanup
                }
            });
        
            // Remove the participant's media container
            const participantDiv = document.getElementById(participant.sid);
            if (participantDiv) {
                participantDiv.remove(); // Remove media div from DOM
            }
        }
          
          function trackSubscribed(div, track) {
            div.appendChild(track.attach());
          }
          
          function trackUnsubscribed(track) {
            track.detach().forEach(element => element.remove());
          }
        }

        return () => {
            if (roomRef.current) {
              roomRef.current.disconnect();
            }
            setIsTimerRunning(false);
        };
    }, [token, roomname, room]);

    // Timer logic to update every second
    useEffect(() => {
        let interval;
        if (isTimerRunning || timerCheck) {
            interval = setInterval(() => {
                setTimer((prevTime) => prevTime + 1);
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [isTimerRunning, closeModal, timerCheck ]);

    // Send all the data to the backend when the call ends (when the last participant disconnects)
    const sendCallDataToBackend = async () => {
        try {
            const data = {
                duration: timer,
                kioskName: roomname,  // Use roomname as the kioskName (or get kiosk details from backend if needed)
                participantEmail: identity, // Assuming the second participant is the user
                organisationId: organisation
            };
            
            // Log the data to the console to inspect it
            const response = await axios.post('https://memoreceptie-api-gmeef6gqceg7bugp.westeurope-01.azurewebsites.net/calls/create', {
                duration: timer,
                kioskName: roomname,  // Use roomname as the kioskName (or get kiosk details from backend if needed)
                participantEmail: identity, // Assuming the second participant is the user
                organisationId: organisation
            });
        } catch (error) {
            console.error('Error saving call data:', error);
        }
    };

      // Function to close the modal
    const closeModalItem = () => {
        if (roomRef.current) {
          if (localTracks) {
            localTracks.forEach(track => {
                track.stop(); // Stop each track (audio/video)
                track.detach().forEach(element => element.remove()); // Detach from the DOM
            });
          }
          roomRef.current.disconnect(); // Disconnect the room safely
        }
        socket.emit('leaveRoom');
        isModalOpen = false;
        setIsModalVisible(false);
        setIsTimerRunning(false);
        setTimer(0);
        closeModal()


        sendCallDataToBackend()
    };

      // Format time to display in mm:ss
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);  // Calculate total minutes
        const secs = seconds % 60;  // Calculate remaining seconds
        return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    useEffect(() => {
        if (!identity.includes('@')) {
            socket.on('disconnectFromRoom', (data) => {
                // Disconnect from the room if it's active
                if (roomRef.current) {
                    roomRef.current.disconnect(); // Disconnect the room
                }

                // Stop and detach local tracks if available
                if (localTracks && localTracks.length > 0) {
                    localTracks.forEach(track => {
                        track.stop();
                        track.detach().forEach(element => element.remove());
                    });
                    setLocalTracks(null); // Reset localTracks state
                } else {
                }

                // Show the "Thanks for calling" message
                setShowThanksMessage(true);
                
                // Fade out the message after a short delay
                setTimeout(() => {
                  setOpacity(0); // Trigger fade-out by setting opacity to 0
                }, 1500); // Short delay before starting fade-out

                // After 5 seconds, navigate to the kiosk page
                setTimeout(() => {
                  navigate(`/kiosk/${oid}/${kid}`);
                }, 3000); // 5000 ms = 5 seconds
            })
        }
        return () => {
          socket.off('disconnectFromRoom'); // Remove the event listener
        };
    }, [room, identity, localTracks, roomRef, navigate, oid, kid])

    return (
        identity.includes('@') ? (
            <Dialog 
              open={isModalVisible} 
              onClose={(event, reason) => {
                if (reason === 'backdropClick') {
                  // Don't close the modal when clicking outside
                  return;
                }
                // Close the modal for other reasons (e.g., escape key)
                closeModal();
              }}
              className='video--modal'
              sx={{
                '& .MuiDialog-paper': {
                  width: '80vw',
                  height: '85vh',
                  maxWidth: 'none',  // Prevent the modal from using its default max-width
                  maxHeight: 'none', // Prevent the modal from using its default max-height
                }
              }}
            >
              <DialogTitle sx={{ display: 'flex', flexDirection:'row', justifyContent: 'space-between', alignItems: 'center', gap: '1rem'}}>
                <div style={{ display: 'flex', gap: '1rem', alignItems:'center'}}>
                  <FaVideo />{roomname}
                </div>
                <div style={{ display: 'flex', gap: '1rem', alignItems:'center'}}>
                  <CgMediaLive color='green' />{formatTime(timer)}
                </div>
              </DialogTitle>
              <DialogContent sx={{ overflow: 'hidden' }} disableBackDropClick>
                <div className="video--wrapper">
                  <div id="remote-media"></div>
                  <div id="local-media"></div>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeModalItem} color="error" sx={{ position: 'absolute', right: '1rem', bottom: '1rem', backgroundColor: 'white', width: '10rem', height: '5rem', borderTopLeftRadius: '5rem', zIndex: 99}}>
                  <ImPhoneHangUp color='red' size={40}/>
                </Button>
              </DialogActions>
            </Dialog>
          )
          :
          ( 
            <div>
                {showThanksMessage ? (
                    <div
                    style={{
                        width: '100%',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        zIndex: 100
                    }}
                    >
                    <h2 style={{
                        opacity: opacity, // Apply opacity to trigger fade-out effect
                        transition: 'opacity 2.5s ease-out', // Smooth fade-out transition over 4.5 seconds
                    }}
                    >
                        Wij wensen je nog een fijne dag verder!
                    </h2>
                    </div>
                ) : (
                    <>
                        <div id="local-media"></div>
                        <div id="remote-media"></div>
                    </>
                )
            }
            </div>
        )
    );
};

export default RoomComponent;
