import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';  // Import jwt-decode
import { Card, CardContent, Typography, Grid, TextField, Box, CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const OrganisationsList = () => {
  const [organisations, setOrganisations] = useState([]);
  const [filteredOrganisations, setFilteredOrganisations] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false); // To manage modal state
  const [userId, setUserId] = useState(''); // To store the user ID from the JWT
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the user ID from the JWT token in sessionStorage
    const token = sessionStorage.getItem('jwt_token');
    if (token) {
      const decodedToken = jwtDecode(token);  // Decode the JWT token using jwt-decode
      setUserId(decodedToken.id);  // Assuming the user ID is stored in _id within the token payload
    }

    const fetchOrganisations = async () => {
      try {
        if (!token) {
          console.error('No JWT token found in sessionStorage');
          return;
        }

        const response = await axios.get('https://memoreceptie-api-gmeef6gqceg7bugp.westeurope-01.azurewebsites.net/organisations', {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });

        setOrganisations(response.data);
        setFilteredOrganisations(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching organisations:', error);
        setLoading(false);
      }
    };

    fetchOrganisations();
  }, []);

  useEffect(() => {
    const filtered = organisations.filter(org =>
      org.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredOrganisations(filtered);
  }, [searchTerm, organisations]);

  const handleCardClick = (organisationName) => {
    navigate(`org/${organisationName}`);
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  // Formik validation schema
  const validationSchema = Yup.object({
    name: Yup.string().required('Organisation name is required'),
    // 'createdBy' is not required anymore in the frontend, handled by backend
  });

  // Formik form
  const formik = useFormik({
    initialValues: {
      name: '',
      // 'createdBy' is automatically handled by the backend via the JWT
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const token = sessionStorage.getItem('jwt_token');
        if (!token) {
          console.error('No JWT token found');
          return;
        }

        // Make the API request to create a new organisation
        const response = await axios.post('https://memoreceptie-api-gmeef6gqceg7bugp.westeurope-01.azurewebsites.net/organisations', values, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });

        // Refresh organisations after adding a new one
        setOrganisations(prev => [...prev, response.data]);
        setFilteredOrganisations(prev => [...prev, response.data]);
        setOpenModal(false);  // Close the modal
      } catch (error) {
        console.error('Error creating organisation:', error);
      }
    },
  });
  

  return (
    <Box className="organisation--box">
      {/* New section: Total Organisations count */}
      <Box sx={{
        backgroundColor: '#0288d1', 
        color: 'white', 
        padding: '10px 20px', 
        marginBottom: '20px', 
        borderRadius: '4px', 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center'
      }}>
        <Typography className='organisation--box__header'>
          Total Organisations: {filteredOrganisations.length}
        </Typography>
      </Box>
      {/* Search and Add Organisation button container */}
      <Box sx={{ display: 'flex', marginBottom: '20px' }}>
        <TextField
          label="Search by organisation name"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ marginRight: '10px', flex: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ backgroundColor: '#f79251' }}
          onClick={handleOpenModal}  // Open modal when clicked
        >
          Add New Organisation
        </Button>
      </Box>
      
      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3}>
          {/* Show filtered organisations */}
          {filteredOrganisations.length > 0 ? (
            filteredOrganisations.map(org => (
              <Grid item xs={12} sm={6} md={4} key={org._id}>
                <Card 
                  sx={{
                    display: 'flex', 
                    flexDirection: 'column', 
                    height: '100%',
                    cursor: 'pointer',
                    backgroundColor: 'white',  // Default background color
                    '&:hover': {
                      boxShadow: 3,
                      backgroundColor: '#e6f2f6',  // Change background color on hover
                    },
                  }} 
                  onClick={() => handleCardClick(org._id)}
                >
                  <CardContent>
                    <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                      {org.name}
                    </Typography>
                    {/* Display the user's email who created the organisation */}
                    <Typography variant="body2" color="text.secondary">
                      Created By: {org.createdBy.email} {/* Accessing populated email field */}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography variant="h6" align="center">
                No organizations found.
              </Typography>
            </Grid>
          )}
        </Grid>
      )}

      {/* Modal for Adding a New Organisation */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Add New Organisation</DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              label="Organisation Name"
              variant="outlined"
              margin="normal"
              {...formik.getFieldProps('name')}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            {/* Disabled 'Created By' field with the pre-filled user ID */}
            <TextField
              fullWidth
              label="Created By"
              variant="outlined"
              margin="normal"
              value={userId}  // Display the current user ID
              disabled  // Make this field read-only
            />
            <DialogActions>
              <Button onClick={handleCloseModal} color="primary">Cancel</Button>
              <Button type="submit" color="primary">Create</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default OrganisationsList;
