import React from 'react';
import useRoomActivity from '../hooks/useRoomActivity';
import BaseLayout from '../layouts/BaseLayout';

const Admin = () => {
  const activity = useRoomActivity();  // Get activity via the custom hook

  return (
    <BaseLayout>
      <div className="dashboard-container">
        {/* Sidebar Component */}
        {/* Main content area */}
        <div className="main-content">
          {/* Display the activity feed if needed */}
          <div className="activity-feed">
            <p>Binnenkort beschikbaar.</p>
            <ul>
              {activity && activity.map((event, index) => (
                <li key={index}>{event.roomName} - {event.action}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default Admin;
