import { useState, React, useEffect } from 'react';
import { Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import { FaUserCircle } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import Memo from '../media/images/memo.svg'
import { IoMdArrowDropright } from "react-icons/io";
import { useSocket } from '../socket.context';
import Smile from '../media/images/Smile.png'

const SideBar = ({ user, handleLogout, incomingCall, onPickUp, onDecline }) => {

  const { socket } = useSocket();
  const [usersList, setUsersList] = useState([]);
  const [kioskList, setKioskList] = useState([]);

  useEffect(() => {
    if (socket && user) {
      socket.on('usersList', (users) => {
        const filteredUsers = users.filter(u => u.email !== '' && u.email !== user.email);
        const filteredKiosks = users.filter(user => user.email === '');
      // Set the state for usersList and kioskList
      setUsersList(filteredUsers);
      setKioskList(filteredKiosks);
      });
    }
  }, [socket])

  const [isAdminOpen, setIsAdminOpen] = useState(false);

  const toggleAdminMenu = () => {
    setIsAdminOpen(!isAdminOpen); // Toggle the visibility of the submenu
  };

  // If user is not available, show loading or a fallback
  if (!user) {
    return <div>Loading...</div>;  // Show a loading message or a spinner
  }

  return (
    <div className="sidebar">
      <div>
        <section className="sidebar-header">
          <NavLink to="/">
            <img src={ Memo } height='20rem'/>
            <h2 style={{ color: '#0E426A' }}>video</h2>
          </NavLink>
        </section>
        <Divider sx={{ borderColor: 'white' }} orientation="horizontal" flexItem />

            <div className='profile-flex'>
                <FaUserCircle />
                <h5>{user.email}</h5>
            </div>
        <div className='administratie-container'>
          {user.role === "superadmin" && (
            <>
              <ul>
                <h3 onClick={toggleAdminMenu} className='admin--toggleMenuItem'><IoMdArrowDropright className={`admin--toggleMenuItem__icon ${isAdminOpen ? 'rotate' : ''}`} /><p>Administratie</p></h3>
                {/* Conditionally render the submenu based on the state */}
                <div className={`flexContainer-adminLinks ${isAdminOpen ? 'open' : ''}`}>
                  <NavLink to="/superadmin">
                    Admin
                  </NavLink>
                </div>
              </ul>
            </>
          )}
          {user.role === "admin" && (
            <>
              <ul>
                <h3 onClick={toggleAdminMenu} className='admin--toggleMenuItem'><IoMdArrowDropright className={`admin--toggleMenuItem__icon ${isAdminOpen ? 'rotate' : ''}`} /><p>Administratie</p></h3>
                {/* Conditionally render the submenu based on the state */}
                <div className={`flexContainer-adminLinks ${isAdminOpen ? 'open' : ''}`}>
                  <NavLink to="/admin" activeClassName="active">
                    Organisatiebeheer
                  </NavLink>
                </div>
              </ul>
            </>
          )}
        </div>
      </div>
      <div>
        <div className='collegas'>
          <img src={Smile} alt="Smile" height="20" />
          <h5 style={{ margin: '0'}}>Aangemelde collega's</h5>
          {usersList.length === 0 ? (
            <p style={{ fontSize: '13px' }}>Geen collega's zijn aangemeld</p>
          ) : (
            usersList.map((user) => (
              <li className='collegas--flex' key={user.id}>
                <p style={{ margin: '0' }}>{user.email}</p>
                <p>Status: {user.isActive ? 'Actief' : 'Inactief'}</p>
              </li>
            ))
          )}
        </div>
        <Button style={{ backgroundColor: '#024e64' }} className="logout-btn" variant="contained" color="primary" onClick={handleLogout}>
          Uitloggen
        </Button>
      </div>
    </div>
  );
};

export default SideBar;