import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';  // Import jwt-decode
import { Card, CardContent, Typography, Grid, TextField, Box, CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const KiosksList = () => {
  const [kiosks, setKiosks] = useState([]);
  const [filteredKiosks, setFilteredKiosks] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false); // To manage modal state
  const [organisationId, setOrganisationId] = useState(''); // To store the user ID from the JWT
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the user ID from the JWT token in sessionStorage
    const token = sessionStorage.getItem('jwt_token');
    if (token) {
      const decodedToken = jwtDecode(token);  // Decode the JWT token using jwt-decode
      setOrganisationId(decodedToken.organisationId);  // Assuming the user ID is stored in _id within the token payload
    }

    const fetchKiosks = async () => {
      try {
        if (!token) {
          console.error('No JWT token found in sessionStorage');
          return;
        }

        const response = await axios.get('https://memoreceptie-api-gmeef6gqceg7bugp.westeurope-01.azurewebsites.net/kiosks', {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });

        setKiosks(response.data);
        setFilteredKiosks(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching kiosks:', error);
        setLoading(false);
      }
    };

    fetchKiosks();
  }, []);

  useEffect(() => {
    const filtered = kiosks.filter(kiosk =>
      kiosk.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredKiosks(filtered);
  }, [searchTerm, kiosks]);

  const handleCardClick = (kioskId, organisation_Id) => {
    window.open(`/kiosk/${organisation_Id}/${kioskId}`, '_blank');
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  // Formik validation schema
  const validationSchema = Yup.object({
    name: Yup.string().required('Kiosk name is required'),
  });

  // Formik form
  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const token = sessionStorage.getItem('jwt_token');
        if (!token) {
          console.error('No JWT token found');
          return;
        }

        // Include the organisationId in the form data before submitting
        const formData = { ...values, organisation_id: organisationId };

        // Make the API request to create a new kiosk
        const response = await axios.post('https://memoreceptie-api-gmeef6gqceg7bugp.westeurope-01.azurewebsites.net/kiosks', formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });

        // Refresh kiosks after adding a new one
        setKiosks(prev => [...prev, response.data]);
        setFilteredKiosks(prev => [...prev, response.data]);
        setOpenModal(false);  // Close the modal
      } catch (error) {
        console.error('Error creating kiosk:', error);
      }
    },
  });

  return (
    <Box className="kiosk--box">
      <Box sx={{
        backgroundColor: '#0288d1', 
        color: 'white', 
        padding: '10px 20px', 
        marginBottom: '20px', 
        borderRadius: '4px', 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center'
      }}>
        <Typography className='kiosk--box__header'>
          Total Kiosks: {filteredKiosks.length}
        </Typography>
      </Box>

      {/* Search and Add Kiosk button container */}
      <Box sx={{ display: 'flex', marginBottom: '20px' }}>
        <TextField
          label="Search by kiosk name"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ marginRight: '10px', flex: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ backgroundColor: '#f79251' }}
          onClick={handleOpenModal}  // Open modal when clicked
        >
          Add New Kiosk
        </Button>
      </Box>
      
      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3}>
          {/* Show filtered kiosks */}
          {filteredKiosks.length > 0 ? (
            filteredKiosks.map(kiosk => (
              <Grid item xs={12} sm={6} md={4} key={kiosk._id}>
                <Card 
                  sx={{
                    display: 'flex', 
                    flexDirection: 'column', 
                    height: '100%',
                    cursor: 'pointer',
                    backgroundColor: 'white',
                    '&:hover': {
                      boxShadow: 3,
                      backgroundColor: '#e6f2f6',
                    },
                  }} 
                  onClick={() => handleCardClick(kiosk._id, kiosk.organisation_id._id)}
                >
                  <CardContent>
                    <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                      {kiosk.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Owned by: {kiosk.organisation_id.name}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography variant="h6" align="center">
                No kiosks found.
              </Typography>
            </Grid>
          )}
        </Grid>
      )}

      {/* Modal for Adding a New Kiosk */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Add New Kiosk</DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              label="Kiosk Name"
              variant="outlined"
              margin="normal"
              {...formik.getFieldProps('name')}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            {/* Display the organisation ID as a readonly field */}
            <TextField
              fullWidth
              label="Organisation ID"
              variant="outlined"
              margin="normal"
              value={organisationId}  // Display the current organisation ID
              disabled  // Make this field read-only
            />
            <DialogActions>
              <Button onClick={handleCloseModal} color="primary">Cancel</Button>
              <Button type="submit" color="primary">Create</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default KiosksList;
